@import '../../styles/customMediaQueries.css';

.root {
  display: flex;
  flex-direction: column;
}

.nowrap {
  white-space: nowrap;
}

.details {
  /* Layout */
  padding-top: 24px;
  padding-top: 24px;
  margin-top: 24px;

  /* Style */
  border-top-width: 1px;
  border-top-style: solid;
  border-top-color: var(--matterColorNegative);
}

.detailsTitle {
  margin-top: 0;
  margin-bottom: 6px;

  /* Font */
  font-size: 16px;
  line-height: 20px;
  font-weight: var(--fontWeightSemiBold);
}

.lineItems {
  display: flex;
  flex-direction: column;
}

.lineItem {
  /* Layout */
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  /* Font */
  font-size: 14px;
  line-height: 24px;
}

.itemLabel {
  flex: 1;
  white-space: nowrap;
}

.itemValue,
.listingItemValue {
  margin: 0 0 0 10px;
}

.listingItemValue {
  text-align: right;

  /* Limit to only one line */
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  line-clamp: 1;
  -webkit-box-orient: vertical;

  &:hover {
    color: var(--marketplaceVariationColor);
  }
}
